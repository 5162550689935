<template>
  <div class="flexbox">
    <div>
      <img src="@/assets/whoarewe.png" class="image">
    </div>
    <div class="text-wrap">
      <div class="title">
        <p>WHO ARE WE</p>
      </div>
      <div class="text">
        <p>
          We are a new 'dynamic studio' that can bring your ideas to life.<br>
          <br>
          At WYD, we like to challenge traditional norms by adding our vibrant, youthful and bold touch!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoAreWe'
}
</script>

<style scoped>



</style>
