<template>
  <div class="flexbox">
    <div>
      <img src="@/assets/instapage.png" class="image">
    </div>
    <div class="text-wrap">
      <div class="title">
        <p>INSTAGRAM PAGE</p>
      </div>
      <div class="text">
        <p>We set up an Instagram account with the purpose to reach a bigger audience for WYD.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstagramPage'
}
</script>

<style scoped>



</style>
