<template>
    <p class="slogan">BRINGING YOUR IDEAS TO LIFE.</p>
  </template>
  
  <script>
  export default {
    name: 'SloganText'
  }
  </script>
  
  <style scoped>
  .slogan {
    font-family: "League Spartan", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 6vw; /* Adjust the font size as needed */
    color: white; /* Change the color of the slogan */
    margin-top: 9vh; /* Add some space between the image and the slogan */
  }
  </style>