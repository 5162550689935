<template>
  <div class="flexbox">
    <div>
      <img src="@/assets/contactus.png" class="image">
    </div>
    <div class="text-wrap">
      <div class="title">
        <p>CONTACT US</p>
      </div>
      <div class="text">
        <p>
            You can contact us here!<br>
            <br>
            Email: wydstudio@gmail.com<br>
            Instagram: WYD_Studio
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs'
}
</script>

<style scoped>

</style>
